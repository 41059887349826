<template>
  <div>
    <v-sheet outlined>
      <div :class="['d-flex', 'align-center', 'pt-10']">
        <v-row class="mx-0 px-4">
          <v-col xl="4" md="4" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              v-model="year"
              :items="yearType === 'year' ? years : financialYears"
              item-text="yearLabel"
              item-value="year"
              label="Year"
            >
            </v-select>
          </v-col>
          <v-col xl="4" md="4" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              v-model="yearType"
              :items="yearTypes"
              item-text="name"
              item-value="value"
              label="Year type"
            ></v-select>
          </v-col>
          <v-col xl="4" md="4" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              clearable
              v-model="distributionType"
              :items="['General', 'Balavedhi', 'Reference', 'E-Reader']"
              label="Distribution Type"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import store from "@/store";
import { toRefs } from "@vue/composition-api";
import moment from "moment";
const filters = store.abstractFilters;
const years = [];
const financialYears = [];
for (let i = parseInt(moment(app.getTime).format("YYYY")); i >= 1990; i--) {
  const yearLabel = filters.yearType === "financialYear" ? `${i}-${i + 1}` : i;
  financialYears.push({ year: i, yearLabel });
  years.push({ year: i, yearLabel: i });
}

const yearTypes = [
  { name: "Financial year", value: "financialYear" },
  { name: "Year", value: "year" }
];

export default {
  setup(props, { root }) {
    return {
      ...toRefs(filters),
      years,
      financialYears,
      yearTypes
    };
  }
};
</script>
