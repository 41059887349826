<template>
  <v-dialog width="600" :value="value" @input="$emit('input', $event)">
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar color="primary" dark>
          <span>{{ title }}</span>
        </v-toolbar>
        <div class="pt-4 pb-2 px-4">
          <div class="text-center">
            <v-card-text v-if="!s3Info.s3FileHeadInfo">
              Previously generated report was not found.
            </v-card-text>
            <v-card-text v-else>
              <span v-if="isRecent"
                >Your recently generated report at
                {{ s3Info.s3FileHeadInfo.LastModified | formatTime }} is ready
                <a :href="s3Info.s3FileHeadInfo.url">click here</a>
                to download.
              </span>
              <span v-else>
                Previously generated report was on
                {{ s3Info.s3FileHeadInfo.LastModified | formatDateTime }},
                <a :href="s3Info.s3FileHeadInfo.url">click here</a>
                to download.
              </span>
            </v-card-text>
          </div>
          <div>
            <v-card-text v-if="s3Info.inProgress">
              You cannot generate report as already a report generation is in
              progress, please
              <a @click.prevent="checkIfGenerationCompleted"> try again </a>
              after some time.
            </v-card-text>
            <v-card-text v-else class="text-center">
              <v-btn
                class="primary"
                tile
                text
                @click="startGenerate(api)"
                :loading="isGenerating"
              >
                Generate report as {{ ext }} file and download
              </v-btn>
            </v-card-text>
          </div>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.value = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { useReportDownloadWindow } from "@/modules";

export default {
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    },
    api: {
      type: String
    },
    s3Info: {
      type: Object,
      default: null
    },
    checkIfGenerationCompleted: {
      type: Function
    }
  },
  setup: useReportDownloadWindow
};
</script>
