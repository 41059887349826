import { onMounted, computed, toRefs, watch } from "@vue/composition-api";
import store from "@/store";
import axios from "axios";

const state = store.reports;
const bookFilters = store.booksReport.filters;
const memberFilters = store.membersReports.filters;
const logFilters = store.logsReports.filters;
const abstractFilters = store.abstractFilters;
const typeWiseAbstract = store.typeWiseAbstract;

const getFileInfo = ({
  fileName,
  onSuccess,
  onCatch,
  onComplete,
  showLoader
}) => {
  showLoader && store.app.showLoader("Checking if report generated");
  axios
    .get(`${process.env.VUE_APP_API}/reportsData`, {
      params: { fileName: fileName }
    })
    .then((response) => {
      onSuccess && onSuccess(response);
    })
    .catch((error) => {
      onCatch && onSuccess(error);
    })
    .finally(() => {
      showLoader && store.app.hideLoader();
      onComplete && onComplete();
    });
};

const setReportWindowState = (response, api, title) => {
  const info = response.data;
  state.reportWindowS3Info = info;
  if (api) {
    state.showReportWindow = true;
    state.reportWindowTitle = title;
    state.reportWindowApi = api;
  }
};

const checkIfGenerationCompleted = (showLoader = true) => {
  getFileInfo({
    showLoader,
    fileName: state.reportWindowFileName,
    onSuccess: (response) => setReportWindowState(response),
    onComplete: () => (bookFilters.isPdfLoading = false),
    onError: () => {
      store.app.showAlert("Some error occured!");
    }
  });
};

const downloadBooksAsPdf = () => {
  const fileName = "books-report.pdf";
  const query = `?showAll=true&getAsPdf=true${store.booksReport.filterQuery}`;
  const api = `${process.env.VUE_APP_API}/book${query}`;
  state.reportWindowFileName = fileName;
  if (!bookFilters.isPdfLoading) {
    bookFilters.isPdfLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(response, api, "Download books report as pdf"),
      onComplete: () => (bookFilters.isPdfLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadBooksAsXlsx = () => {
  const fileName = "books-report.xlsx";
  const query = `?getAsXlsx=true${store.booksReport.filterQuery}`;
  const api = `${process.env.VUE_APP_API}/book${query}`;
  state.reportWindowFileName = fileName;
  if (!bookFilters.isXlsxLoading) {
    bookFilters.isXlsxLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download books report as xlsx file"
        ),
      onComplete: () => (bookFilters.isXlsxLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadMembersAsPdf = () => {
  const fileName = "members-report.pdf";
  const query = `?getAsPdf=true${store.membersReports.filterQuery}`;
  const api = `${process.env.VUE_APP_API}/member${query}`;
  state.reportWindowFileName = fileName;
  if (!memberFilters.isPdfLoading) {
    memberFilters.isPdfLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(response, api, "Download members report as pdf"),
      onComplete: () => (memberFilters.isPdfLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadMembersAsXlsx = () => {
  const fileName = "members-report.xlsx";
  const query = `?getAsXlsx=true${store.membersReports.filterQuery}`;
  const api = `${process.env.VUE_APP_API}/member${query}`;
  state.reportWindowFileName = fileName;
  if (!memberFilters.isXlsxLoading) {
    memberFilters.isXlsxLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download members report as xlsx file"
        ),
      onComplete: () => (memberFilters.isXlsxLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadLogsAsPdf = () => {
  const fileName = "distribution-logs-report.pdf";
  const query = `?getAsPdf=true${store.logsReports.filterQuery}`;
  const api = `${process.env.VUE_APP_API}/log${query}`;
  state.reportWindowFileName = fileName;
  if (!logFilters.isPdfLoading) {
    logFilters.isPdfLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download distribution logs report as pdf"
        ),
      onComplete: () => (logFilters.isPdfLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadLogsAsXlsx = () => {
  const fileName = "distribution-logs-report.xlsx";
  const query = `?getAsXlsx=true${store.logsReports.filterQuery}`;
  const api = `${process.env.VUE_APP_API}/log${query}`;
  state.reportWindowFileName = fileName;
  if (!logFilters.isXlsxLoading) {
    logFilters.isXlsxLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download distribution logs report as xlsx file"
        ),
      onComplete: () => (logFilters.isXlsxLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadAbstractsAsPdf = () => {
  const fileName = "monthly-distributions-report.pdf";
  let api = `${process.env.VUE_APP_API}/book/abstract?year=${abstractFilters.year}&yearType=${abstractFilters.yearType}&getAsPdf=true`;
  if (abstractFilters.distributionType) {
    api += `&type=${abstractFilters.distributionType.toLowerCase()}`;
  }
  state.reportWindowFileName = fileName;
  if (!abstractFilters.isPdfLoading) {
    abstractFilters.isPdfLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download monthly distributions report as pdf"
        ),
      onComplete: () => (abstractFilters.isPdfLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadTypeWiseAbstractsAsPdf = () => {
  const fileName = "books-category-wise-report.pdf";
  const api = `${process.env.VUE_APP_API}/book/typeWiseAbstract?getAsPdf=true`;
  state.reportWindowFileName = fileName;
  if (!typeWiseAbstract.isPdfLoading) {
    typeWiseAbstract.isPdfLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download books category wise report as pdf"
        ),
      onComplete: () => (typeWiseAbstract.isPdfLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadTypeWiseAbstractsAsXlsx = () => {
  const fileName = "books-category-wise-report.xlsx";
  const api = `${process.env.VUE_APP_API}/book/typeWiseAbstract?getAsXlsx=true`;
  state.reportWindowFileName = fileName;
  if (!typeWiseAbstract.isXlsxLoading) {
    typeWiseAbstract.isXlsxLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download books category wise report as xlsx"
        ),
      onComplete: () => (typeWiseAbstract.isXlsxLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

const downloadAbstractsAsXlsx = () => {
  const fileName = "monthly-distributions-report.xlsx";
  let api = `${process.env.VUE_APP_API}/book/abstract?year=${abstractFilters.year}&yearType=${abstractFilters.yearType}&getAsXlsx=true`;
  if (abstractFilters.distributionType) {
    api += `&type=${abstractFilters.distributionType.toLowerCase()}`;
  }
  state.reportWindowFileName = fileName;
  if (!abstractFilters.isXlsxLoading) {
    abstractFilters.isXlsxLoading = true;
    getFileInfo({
      fileName,
      onSuccess: (response) =>
        setReportWindowState(
          response,
          api,
          "Download monthly distributions report as xlsx file"
        ),
      onComplete: () => (abstractFilters.isXlsxLoading = false),
      onError: () => {
        store.app.showAlert("Some error occured!");
      }
    });
  }
};

export default (props, { root }) => {
  onMounted(() => {
    if (!bookFilters.searchByTypeFilters.length) {
      bookFilters.loadBookTypes();
      store.logsReports.memberFilter("");
    }
    if (!memberFilters.membershipPlanFilters.length) {
      memberFilters.loadMemberClasses();
    }
  });

  watch(
    () => logFilters.memberSearchKey,
    (value) => {
      store.logsReports.memberFilter(value);
    }
  );

  const isSubLibrary = computed(() =>
    store.mainLayout?.state?.libraryParent ? true : false
  );
  return {
    ...toRefs(state),
    book: toRefs(bookFilters),
    log: toRefs(logFilters),
    member: toRefs(memberFilters),
    abstract: toRefs(abstractFilters),
    typeWiseAbstract: toRefs(typeWiseAbstract),
    resetBookFilters: () => bookFilters.resetFilter(),
    resetAbstractFilters: () => abstractFilters.resetYear(),
    resetLogFilters: () => logFilters.resetFilter(),
    resetMemberFilters: () => memberFilters.resetFilter(),
    isSubLibrary,
    downloadBooksAsPdf,
    downloadBooksAsXlsx,
    downloadLogsAsPdf,
    downloadLogsAsXlsx,
    downloadMembersAsXlsx,
    downloadMembersAsPdf,
    downloadAbstractsAsPdf,
    downloadTypeWiseAbstractsAsPdf,
    downloadTypeWiseAbstractsAsXlsx,
    checkIfGenerationCompleted,
    downloadAbstractsAsXlsx
  };
};
