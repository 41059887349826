<template>
  <div>
    <v-btn
      v-if="!isReportFilter"
      @click="toggleFilterOnXs()"
      class="search-bar-toggle d-md-none"
      ><v-icon>mdi-dots-horizontal</v-icon></v-btn
    >
    <v-sheet
      :class="[
        !isReportFilter && 'logs-filters-parent',
        !isReportFilter && showFilterOnXs && 'show',
        isReportFilter && 'pt-10 in-report-filter',
        showFilterOnXs ? 'show' : ''
      ]"
      outlined
    >
      <div
        v-if="!isReportFilter"
        :class="['px-6 pt-2 d-flex justify-end align-center']"
        style="height: 30px"
      >
        <v-checkbox
          v-if="!isSubLibrary"
          v-model="subLibraryLogs"
          dense
          label="Distributions to Sub Library"
          class="mt-3 mr-2"
        ></v-checkbox>
        <v-btn tile x-small @click="resetFilter()" class="ml-2">Reset</v-btn>
        <v-btn tile x-small @click="toggleFilterOnXs()" class="ml-2 d-md-none">
          Hide
        </v-btn>
      </div>
      <div :class="['d-flex', 'align-center']">
        <v-row class="mx-0 px-4">
          <v-col
            :xl="subLibraryLogs || isSubLibrary ? 8 : 4"
            :md="subLibraryLogs || isSubLibrary ? 8 : 4"
            sm="12"
            cols="12"
            class="px-0 py-0"
          >
            <v-autocomplete
              v-if="!subLibraryLogs"
              v-model="member"
              clearable
              :items="membersData"
              :loading="isMemberLoading"
              :search-input.sync="memberSearchKey"
              :filter="filterMembers"
              item-text="name"
              item-value="_id"
              label="Member"
              class="mx-2"
              dense
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="`# ${data.item.memberNumber} ${data.item.name}`"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <vc-data-autocomplete
              v-else
              api="/subLibraries"
              dense
              v-model="subLibrary"
              item-text="name"
              item-value="_id"
              class="mx-2"
              label="Sub Library"
              clearable
            />
          </v-col>
          <v-col
            v-if="!subLibraryLogs && !isSubLibrary"
            xl="4"
            md="4"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              dense
              clearable
              class="mx-2"
              v-model="distributionType"
              :items="['General', 'Balavedhi', 'Reference', 'E-Reader']"
              label="Distribution Type"
            ></v-select>
          </v-col>
          <v-col xl="4" md="4" sm="12" cols="12" class="px-0 py-0 my-0">
            <v-select
              dense
              class="mx-2"
              v-model="stock"
              :items="searchByStock"
              item-text="name"
              item-value="_id"
              label="Status"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
      <div :class="['d-flex', 'align-center']">
        <v-row class="mx-0 px-4">
          <v-col
            xl="4"
            md="4"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0 date-type"
          >
            <v-select
              dense
              class="mx-2"
              v-model="dateType"
              :items="dateTypes"
              item-text="name"
              item-value="_id"
              label="Date type"
            >
            </v-select>
          </v-col>

          <v-col
            :xl="isSubLibrary ? 4 : 2"
            :md="isSubLibrary ? 4 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0 date-selector"
          >
            <v-menu
              v-if="dateType === 'month'"
              ref="monthMenu"
              v-model="monthMenu"
              :close-on-content-click="false"
              :return-value.sync="logMonth"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hint="Select a date range"
                  dense
                  class="mx-2"
                  v-model="logMonthText"
                  label="Log month"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  append-icon="mdi-calendar-range"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="logFilterCurrentDate"
                no-title
                v-model="logMonth"
                :reactive="true"
                :range="dateType === 'date_range'"
                type="month"
                scrollable
                ><v-spacer></v-spacer>
                <v-btn text color="primary" @click="monthMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.monthMenu.save(logMonth)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <span v-else>
              <v-menu
                ref="rangeMenu"
                v-model="rangeMenu"
                :close-on-content-click="false"
                :return-value.sync="logDates"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hint="Select a date range"
                    dense
                    class="mx-2"
                    v-model="logDateRangeText"
                    label="Log date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :append-icon="
                      logDateRangeText && logDateRangeText.includes('~')
                        ? ''
                        : 'mdi-calendar-range'
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  :max="logFilterCurrentDate"
                  no-title
                  v-model="logDates"
                  :reactive="true"
                  range
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="rangeMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.rangeMenu.save(logDates)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </span>
          </v-col>

          <v-col
            :xl="isSubLibrary ? 4 : 2"
            :md="isSubLibrary ? 4 : 2"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0 date-type"
          >
            <v-select
              dense
              class="mx-2"
              v-model="orderBy"
              :items="orderTypes"
              item-text="name"
              item-value="_id"
              label="Order by"
            >
            </v-select>
          </v-col>

          <v-col
            v-if="!isSubLibrary"
            xl="4"
            md="4"
            sm="12"
            cols="12"
            class="px-0 py-0 my-0"
          >
            <v-select
              :loading="users === null && !isSubLibrary"
              dense
              class="mx-2"
              v-model="createdBy"
              :items="users || []"
              item-text="name"
              item-value="_id"
              clearable
              label="Distributed By"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import store from "@/store";
import { computed, onMounted, toRefs } from "@vue/composition-api";
import axios from "axios";

const filterMembers = (item, queryText) => {
  queryText = queryText.trim().toLocaleLowerCase();
  item.name = item.name.trim().toLocaleLowerCase();
  const nameFound =
    (queryText.length === 1 && queryText == item.name[0]) ||
    (queryText.length > 1 && item.name.includes(queryText));
  const memberNumberFound = !isNaN(queryText) && queryText == item.memberNumber;
  return !queryText.length || nameFound || memberNumberFound;
};

export default {
  props: ["isReportFilter"],
  setup(props) {
    const isSubLibrary = computed(() =>
      store.mainLayout?.state?.libraryParent ? true : false
    );
    onMounted(() => {
      if (store.logs.filters.users === null && !isSubLibrary.value) {
        axios.get(`${process.env.VUE_APP_API}/admin/users`).then((result) => {
          store.logs.filters.users = store.logsReports.filters.users =
            result.data;
        });
      }
    });
    return {
      ...(props.isReportFilter
        ? toRefs(store.logsReports.filters)
        : toRefs(store.logs.filters)),
      rangeMenu: false,
      monthMenu: false,
      isSubLibrary,
      filterMembers: filterMembers,
      dateTypes: [
        { name: "Month", _id: "month" },
        { name: "Date range", _id: "date_range" }
      ],
      orderTypes: [
        { name: "Recent first", _id: "-1" },
        { name: "Oldest first", _id: "1" }
      ]
    };
  }
};
</script>
