<template>
  <div style="height: 100%" class="reports">
    <div class="reports-parent" style="height: 100%" ref="scrollable" v-bar>
      <div>
        <v-container>
          <v-card tile>
            <v-subheader class="px-6 report-header">
              <h3>Books report</h3>
              <div
                class="report-button-wrapper d-block d-sm-flex justify-end align-center"
              >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="book.isPdfLoading.value"
                  @click="downloadBooksAsPdf"
                  >Pdf download</v-btn
                >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="book.isXlsxLoading.value"
                  @click="downloadBooksAsXlsx"
                  >Excel download</v-btn
                >
                <v-btn class="mr-3" tile x-small @click="resetBookFilters"
                  >Reset</v-btn
                >
              </div>
            </v-subheader>
            <BooksFilters :isReportFilter="true" />
          </v-card>
        </v-container>
        <v-container>
          <v-card tile>
            <v-subheader class="px-6 report-header">
              <h3>Members report</h3>
              <div
                class="report-button-wrapper d-block d-sm-flex justify-end align-center"
              >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="member.isPdfLoading.value"
                  @click="downloadMembersAsPdf"
                  >Pdf download</v-btn
                >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="member.isXlsxLoading.value"
                  @click="downloadMembersAsXlsx"
                  >Excel download</v-btn
                >
                <v-btn tile x-small @click="resetMemberFilters">Reset</v-btn>
              </div>
            </v-subheader>
            <MembersFilters :isReportFilter="true" />
          </v-card>
        </v-container>
        <v-container>
          <v-card tile>
            <v-subheader class="px-6 report-header logs">
              <h3>Distribution logs report</h3>
              <div
                class="report-button-wrapper d-block d-sm-flex justify-end align-center"
              >
                <v-checkbox
                  v-if="!isSubLibrary"
                  v-model="log.subLibraryLogs.value"
                  dense
                  label="Distributions to Sub Library"
                  class="mt-4 pr-3"
                ></v-checkbox>
                <v-btn
                  tile
                  x-small
                  class="primary mr-3"
                  :loading="log.isPdfLoading.value"
                  @click="downloadLogsAsPdf"
                >
                  Pdf download
                </v-btn>
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="log.isXlsxLoading.value"
                  @click="downloadLogsAsXlsx"
                >
                  Excel download
                </v-btn>
                <v-btn tile x-small @click="resetLogFilters">
                  Reset
                </v-btn>
              </div>
            </v-subheader>
            <LogsFilters :isReportFilter="true" />
          </v-card>
        </v-container>
        <v-container>
          <v-card tile>
            <v-subheader class="px-6 report-header">
              <h3>Monthly distributions report</h3>
              <div
                class="report-button-wrapper d-block d-sm-flex justify-end align-center"
              >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="abstract.isPdfLoading.value"
                  @click="downloadAbstractsAsPdf"
                  >Pdf download</v-btn
                >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="abstract.isXlsxLoading.value"
                  @click="downloadAbstractsAsXlsx"
                  >Excel download</v-btn
                >
                <v-btn tile x-small @click="resetAbstractFilters">Reset</v-btn>
              </div>
            </v-subheader>
            <AbstractFilters />
          </v-card>
        </v-container>
        <v-container class="pb-8">
          <v-card tile>
            <v-subheader class="px-6 report-header">
              <h3>Books category wise report</h3>
              <div
                class="report-button-wrapper d-block d-sm-flex justify-end align-center"
              >
                <v-btn
                  tile
                  x-small
                  class="mr-3 primary"
                  :loading="typeWiseAbstract.isPdfLoading.value"
                  @click="downloadTypeWiseAbstractsAsPdf"
                  >Pdf download</v-btn
                >
                <v-btn
                  tile
                  x-small
                  class="primary"
                  :loading="typeWiseAbstract.isXlsxLoading.value"
                  @click="downloadTypeWiseAbstractsAsXlsx"
                  >Excel download</v-btn
                >
              </div>
            </v-subheader>
            <v-card-text class="px-6">
              Category wise report will show the total number of books and price
              in each category.
            </v-card-text>
          </v-card>
        </v-container>
      </div>
    </div>
    <ReportDownloadWindow
      v-model="showReportWindow"
      :title="reportWindowTitle"
      :api="reportWindowApi"
      :s3Info="reportWindowS3Info"
      :checkIfGenerationCompleted="checkIfGenerationCompleted"
    />
  </div>
</template>

<script>
import BooksFilters from "@/components/BooksFilters";
import MembersFilters from "@/components/MembersFilters";
import LogsFilters from "@/components/LogsFilters";
import AbstractFilters from "@/components/AbstractFilters";
import ReportDownloadWindow from "@/components/ReportDownloadWindow.vue";
import useReports from "@/modules/reports";
export default {
  components: {
    BooksFilters,
    MembersFilters,
    LogsFilters,
    AbstractFilters,
    ReportDownloadWindow
  },
  setup: useReports
};
</script>
